import React from "react";
import { useTranslation } from "react-i18next";
import "../style/termeni.css"; // CSS specific pentru acest modal

const Termeni = ({ closeModal }) => {
  const { t } = useTranslation(); // Hook pentru traduceri

  return (
    <div className="modal-overlay-termeni" onClick={closeModal}>
      <div
        className="modal-content-termeni"
        onClick={(e) => e.stopPropagation()}>
        <button className="close-modal" onClick={closeModal}>
          &times;
        </button>

        <h2>{t("company_info")}</h2>
        <p>
          <strong>{t("company_name")}:</strong> upvisionmedia SRL <br />
          <strong>{t("registration_code")}:</strong> RO49087400 <br />
          <strong>Nr. Reg. Com.:</strong> J40/21185/09.11.2023 <br />
          <strong>{t("address")}:</strong> Bucureşti Sectorul 1, Intrarea
          GHEORGHE SIMIONESCU, Nr. 19, Ap. B26, România <br />
          <strong>{t("social_capital")}:</strong> 200 RON <br />
        </p>

        <h2>{t("contact_info")}</h2>
        <p>
          <strong>{t("email")}:</strong> info@upvisionmedia.com <br />
          <strong>{t("phone")}:</strong> +40 744 497 740 <br />
          <strong>{t("website")}:</strong> www.upvisionmedia.com <br />
        </p>

        <h3>{t("terms_conditions")}</h3>
        <p>
          Acest document stabilește termenii și condițiile în care compania
          upvisionmedia SRL furnizează servicii de dezvoltare software. Prin
          utilizarea site-ului nostru și a serviciilor noastre, sunteți de acord
          cu acești termeni.
        </p>

        <h4>{t("offered_services")}</h4>
        <p>
          Compania furnizează servicii de dezvoltare software personalizate,
          inclusiv analiză, proiectare, implementare, testare și întreținere de
          aplicații software. Detaliile fiecărui proiect sunt stabilite în
          contractele individuale cu clienții noștri.
        </p>

        <h4>{t("client_obligations")}</h4>
        <p>
          Clientul trebuie să ofere toate informațiile necesare și corecte
          pentru realizarea proiectului. De asemenea, clientul trebuie să
          colaboreze cu echipa noastră pentru a asigura o bună desfășurare a
          proiectului.
        </p>

        <h4>{t("copyright_intellectual_property")}</h4>
        <p>
          Toate produsele software livrate de upvisionmedia SRL sunt protejate
          de legislația română și internațională privind drepturile de autor.
          Dreptul de utilizare al produselor software va fi transferat
          clientului în conformitate cu termenii stabiliți în contract.
        </p>

        <h4>{t("payment_billing")}</h4>
        <p>
          Plata pentru serviciile furnizate se va face în baza facturilor emise
          de upvisionmedia SRL conform contractului semnat. Neîndeplinirea
          obligațiilor de plată poate duce la suspendarea serviciilor.
        </p>

        <h4>{t("liability_limitation")}</h4>
        <p>
          upvisionmedia SRL nu va fi răspunzătoare pentru daune indirecte,
          incidentale sau de consecință care rezultă din utilizarea serviciilor,
          chiar dacă a fost informat cu privire la posibilitatea acestor daune.
        </p>

        <h3>{t("privacy_policy")}</h3>
        <p>
          În conformitate cu Regulamentul (UE) 2016/679 (GDPR), ne angajăm să
          protejăm datele cu caracter personal ale utilizatorilor noștri.
          Această politică explică modul în care colectăm, utilizăm și protejăm
          informațiile personale.
        </p>

        <h4>1. {t("data_collected")}</h4>
        <p>
          Colectăm date personale precum numele, adresa de email, numărul de
          telefon și alte informații necesare pentru furnizarea serviciilor
          noastre. Datele sunt colectate în mod direct de la utilizatori atunci
          când completează formulare sau ne contactează.
        </p>

        <h4>2. {t("data_purpose")}</h4>
        <p>
          Datele colectate sunt utilizate pentru a furniza servicii de
          dezvoltare software, pentru a comunica cu clienții și pentru a ne
          îmbunătăți serviciile. De asemenea, datele pot fi utilizate pentru a
          respecta obligațiile legale.
        </p>

        <h4>3. {t("user_rights")}</h4>
        <p>
          Utilizatorii au dreptul de a solicita accesul la datele lor personale,
          rectificarea sau ștergerea acestora, restricționarea prelucrării și
          portabilitatea datelor. De asemenea, utilizatorii au dreptul de a se
          opune prelucrării datelor în anumite circumstanțe.
        </p>

        <h4>4. {t("data_security")}</h4>
        <p>
          Ne angajăm să protejăm datele personale prin măsuri tehnice și
          organizatorice adecvate pentru a preveni accesul neautorizat,
          modificarea, divulgarea sau distrugerea datelor.
        </p>

        <h4>5. {t("data_retention")}</h4>
        <p>
          Datele personale sunt păstrate atât timp cât este necesar pentru
          îndeplinirea scopurilor pentru care au fost colectate sau conform
          cerințelor legale.
        </p>

        <h4>6. {t("contact")}</h4>
        <p>
          Pentru orice întrebări legate de protecția datelor sau pentru a
          exercita drepturile dumneavoastră, ne puteți contacta la: <br />{" "}
          <br />
          <strong>Email:</strong> info@upvisionmedia.com
        </p>
      </div>
    </div>
  );
};

export default Termeni;
