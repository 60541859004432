import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../data/en.json";
import translationRO from "../data/ro.json";
import translationIT from "../data/it.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ro: {
    translation: translationRO,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  .use(initReactI18next) // Integrarea cu React
  .init({
    resources,
    lng: "ro", // Setează limba implicită la română
    fallbackLng: "ro", // Folosește limba română ca fallback dacă nu găsește alta
    interpolation: {
      escapeValue: false, // React deja protejează împotriva XSS
    },
  });

export default i18n;
