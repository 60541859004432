import "./App.css";
import Footer from "./pages/Footer";
import Home from "./pages/Home";
import ProjectList from "./pages/ProjectList";

export default function App() {
  return (
    <div className="App">
      <Home />
      <ProjectList />
      <Footer />
    </div>
  );
}
