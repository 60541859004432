import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap"; // Importăm GSAP
import "../style/projectList.css";
import projects from "../data/projects2";

const ProjectList = () => {
  const [hoveredProject, setHoveredProject] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [selectedProject, setSelectedProject] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false); // pentru a controla play/pause video pe mobil
  const modalRef = useRef(null); // Referință pentru modal

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); // Detectăm dacă utilizatorul este pe mobil

  useEffect(() => {
    if (selectedProject && modalRef.current) {
      // Animație pentru deschiderea modalului cu un efect spectaculos
      gsap.fromTo(
        modalRef.current,
        { opacity: 0, scale: 0.5, filter: "blur(10px)" }, // Modalul începe mic, neclar și invizibil
        {
          opacity: 1,
          scale: 1,
          filter: "blur(0px)", // Modalul devine clar
          duration: 0.7, // Durata efectului
          ease: "power4.out", // Efect easing pentru o animație mai fluidă
        }
      );
    }
  }, [selectedProject]); // Efectul rulează când modalul este deschis

  const handleMouseEnter = (project) => {
    setHoveredProject(project);
  };

  const handleMouseLeave = () => {
    setHoveredProject(null);
  };

  const handleMouseMove = (e) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setIsPlaying(false); // Resetează starea play la deschiderea modalului
  };

  const handleModalClose = () => {
    setSelectedProject(null);
    setIsPlaying(false); // Oprește video-ul când se închide modalul
  };

  const handlePlayVideo = () => {
    setIsPlaying(true); // Setează video-ul să înceapă să ruleze
    const videoElement = document.getElementById("project-video");
    if (videoElement) {
      videoElement.play(); // Pornește video-ul
    }
  };

  return (
    <div className="project-list-container">
      {projects.map((project) => (
        <div
          key={project.number}
          className="project-item"
          onClick={() => handleProjectClick(project)}
          onMouseEnter={() => handleMouseEnter(project)}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}>
          <div className="project-item-content">
            <div className="left-side">
              <span className="project-number">{project.number}</span>
              <span className="project-name">{project.name}</span>
            </div>
            <div className="right-side">
              <span className="project-category">#{project.category}</span>
            </div>
          </div>
          <div className="line"></div>
        </div>
      ))}

      {hoveredProject && (
        <div
          className="preview-image"
          style={{
            top: cursorPosition.y + 20,
            left: cursorPosition.x + 20,
            backgroundImage: `url(${hoveredProject.previewImage})`,
          }}></div>
      )}

      {selectedProject && (
        <div className="modal-overlay-projects" onClick={handleModalClose}>
          <div
            className="modal-content"
            ref={modalRef} // Setăm referința la modal pentru animații
            onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <span className="modal-project-name">{selectedProject.name}</span>
              <a
                href={selectedProject.link}
                className="visit-project-link"
                target="_blank"
                rel="noopener noreferrer">
                Visit Project <span className="diagonal-arrow">↗</span>
              </a>
            </div>
            <div className="modal-description">
              {selectedProject.description}
            </div>
            <div className="modal-media">
              {selectedProject.additionalImages.map((img, index) => (
                <img key={index} src={img} alt={`Proiect ${index}`} />
              ))}

              {/* Video cu autoplay pe desktop și buton de Play pe mobil */}
              <div className="video-wrapper" style={{ position: "relative" }}>
                <video
                  id="project-video"
                  src={selectedProject.video}
                  muted
                  loop
                  autoPlay={!isMobile} // Autoplay doar pe desktop
                  controls={isMobile && isPlaying} // Controale doar pe mobil după ce începe video-ul
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    display: "block",
                  }}></video>

                {/* Dacă este pe mobil și video-ul nu este în modul de redare, afișăm butonul de Play */}
                {isMobile && !isPlaying && (
                  <div
                    className="play-button-wrapper"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // fundal întunecat pe mobil înainte de redare
                    }}>
                    <button
                      className="play-video-button"
                      onClick={handlePlayVideo}
                      style={{
                        background: "rgba(255, 255, 255, 0.7)",
                        border: "none",
                        padding: "20px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        fontSize: "18px",
                        color: "black",
                      }}>
                      ▶ Play Video
                    </button>
                  </div>
                )}
              </div>
            </div>
            <button className="modal-close-button" onClick={handleModalClose}>
              Închide
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectList;
