import React, { useState, useEffect } from "react";
import "./App.css";

const LocationAndTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  // const formattedTime = currentTime.toLocaleTimeString("ro-RO", {
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   second: "2-digit",
  // });

  const formattedDate = currentTime.toLocaleDateString("ro-RO", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="location-buc">
      <span className="location-city">
        <span className="location-wrapper">
          <a
            href="https://maps.app.goo.gl/rVZr1Q3YfV5A6kHS6"
            target="_blank"
            style={{ textDecoration: "none", color: "black" }}
            rel="noreferrer">
            București
          </a>
        </span>
      </span>{" "}
      <span className="location-time">
        {/* {formattedTime} <br /> */}
        {formattedDate}
      </span>
    </div>
  );
};

export default LocationAndTime;
