import miedariaVideo from "../assets/miedaria.webm";
import dental from "../assets/dental.webm";

import cautaDentist from "../assets/cautadentist.webm";
import psicologo from "../assets/psicologo.webm";
import proiect3d from "../assets/proiect3d.webm";
import oldvision from "../assets/oldvision.webm";

const projects = [
  {
    number: "01",
    name: "CautăDentist",
    category: "SaaS",
    previewImage:
      "https://tinypic.host/images/2024/09/17/Screenshot-2024-09-14-115804.png",
    description:
      "Descoperă stomatologi de încredere, programează-te online rapid și ușor, și beneficiază de expertiză profesională pentru zâmbetul tău perfect.",
    additionalImages: [
      "https://tinypic.host/images/2024/09/17/Screenshot-2024-09-14-115825.png",
      "https://tinypic.host/images/2024/09/17/Screenshot-2024-09-14-115840.png",
    ],
    video: cautaDentist,
    link: "https://www.cautadentist.ro",
  },
  {
    number: "02",
    name: "Miedăria",
    category: "WebDesign",
    previewImage:
      "https://tinypic.host/images/2024/09/17/Screenshot-2024-09-14-115422.png",
    description: "Băuturi amețitoare din miere de albine.",
    additionalImages: [
      "https://tinypic.host/images/2024/09/14/Screenshot-2024-09-14-115438.png",
      "https://tinypic.host/images/2024/09/17/Screenshot-2024-09-14-115438.png",
    ],
    video: miedariaVideo,
    link: "https://miedaria.ro",
  },

  {
    number: "03",
    name: "Luigi Carlo Collivasone",
    category: "ReDesign",
    previewImage:
      "https://tinypic.host/images/2024/09/19/Screenshot-2024-09-19-081921.png",
    description:
      "Specializzato in disturbi dell'umore, depressione, ansia, attacchi di panico, disturbi alimentari e dipendenze, integro la mindfulness e l'ipnosi ericksoniana per promuovere il benessere mentale nei miei pazienti.",
    additionalImages: [
      "https://tinypic.host/images/2024/09/19/Screenshot-2024-09-19-081921.png",
      "https://tinypic.host/images/2024/09/17/Screenshot-2024-09-14-120200.png",
    ],
    video: psicologo,
    link: "https://luigicollivasone.it",
  },

  {
    number: "04",
    name: "DENTAL by upvm",
    category: "Website",
    previewImage:
      "https://tinypic.host/images/2024/10/23/Screenshot-2024-10-23-102859.png",
    description:
      "An upvisionMedia project to help dental clinics to understand better why it's important to have a website and how this website must be builded.",
    additionalImages: [
      "https://tinypic.host/images/2024/10/23/Screenshot-2024-10-23-102859.png",
      "https://tinypic.host/images/2024/10/23/Screenshot-2024-10-23-102910.png",
    ],
    video: dental,
    link: "https://dental.upvisionmedia.com/",
  },
  {
    number: "05",
    name: "upvisionMedia 2.0 (old version)",
    category: "Website",
    previewImage:
      "https://tinypic.host/images/2024/09/25/Screenshot-2024-09-25-152021.png",
    description:
      "Our oldest website, versione 2.0. This website contains a mixture between parallax effects with video background and a loading effect.",
    additionalImages: [
      "https://tinypic.host/images/2024/09/25/Screenshot-2024-09-25-152040.png",
      "https://tinypic.host/images/2024/09/25/Screenshot-2024-09-25-152057.png",
    ],
    video: oldvision,
    link: "https://oldupvision.netlify.app/",
  },
  {
    number: "06",
    name: "Proiect3D",
    category: "Website",
    previewImage:
      "https://tinypic.host/images/2024/09/18/Screenshot-2024-09-18-082116.png",
    description: "Explorând dimensiunile: Proiecte 3D care iau viață!",
    additionalImages: [
      "https://tinypic.host/images/2024/09/18/Screenshot-2024-09-18-082116.png",
      "https://tinypic.host/images/2024/09/18/Screenshot-2024-09-18-082129.png",
    ],
    video: proiect3d,
    link: "https://proiect3d.ro/",
  },
];

export default projects;
