import React, { useEffect } from "react";
import "../style/home.css";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../data/i18n";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Setăm limba implicită, de exemplu, limba din setările browserului sau altă sursă
    const userLanguage = "ro"; // limba detectată din browser (sau 'en' ca fallback)
    i18n.changeLanguage(userLanguage); // schimbăm limba în funcție de preferințele utilizatorului

    // GSAP animation for text disappearing
    gsap.to(".title-homepage, .subtitle-homepage, .container-scroll", {
      opacity: 0,
      scale: 0.7, // scale down for impact
      y: -150, // move further up for dramatic effect
      duration: 1.5, // longer duration for smoother effect
      ease: "power3.out", // ease for a more impactful animation
      scrollTrigger: {
        trigger: ".firstContainer",
        start: "top top",
        end: "bottom top",
        scrub: true,
      },
    });
  }, [i18n]);

  return (
    <div className="firstContainer">
      <h1
        className="title-homepage"
        dangerouslySetInnerHTML={{ __html: t("welcome") }}
      />
      <h2
        className="subtitle-homepage"
        dangerouslySetInnerHTML={{ __html: t("description") }}
      />
      {/* <div className="reviews-google">
        5{" "}
        <svg
          viewBox="0 0 24 24"
          id="star_filled"
          data-name="star filled"
          xmlns="http://www.w3.org/2000/svg"
          fill="#ffc800">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <rect
              id="Rectangle_4"
              data-name="Rectangle 4"
              width="24"
              height="24"
              fill="none"></rect>{" "}
            <path
              id="Star"
              d="M10,15,4.122,18.09l1.123-6.545L.489,6.91l6.572-.955L10,0l2.939,5.955,6.572.955-4.755,4.635,1.123,6.545Z"
              transform="translate(2 3)"
              stroke="#ffc800"
              stroke-miterlimit="10"
              stroke-width="1.5"></path>{" "}
          </g>
        </svg>
        <svg
          viewBox="0 0 24 24"
          id="star_filled"
          data-name="star filled"
          xmlns="http://www.w3.org/2000/svg"
          fill="#ffc800">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <rect
              id="Rectangle_4"
              data-name="Rectangle 4"
              width="24"
              height="24"
              fill="none"></rect>{" "}
            <path
              id="Star"
              d="M10,15,4.122,18.09l1.123-6.545L.489,6.91l6.572-.955L10,0l2.939,5.955,6.572.955-4.755,4.635,1.123,6.545Z"
              transform="translate(2 3)"
              stroke="#ffc800"
              stroke-miterlimit="10"
              stroke-width="1.5"></path>{" "}
          </g>
        </svg>
        <svg
          viewBox="0 0 24 24"
          id="star_filled"
          data-name="star filled"
          xmlns="http://www.w3.org/2000/svg"
          fill="#ffc800">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <rect
              id="Rectangle_4"
              data-name="Rectangle 4"
              width="24"
              height="24"
              fill="none"></rect>{" "}
            <path
              id="Star"
              d="M10,15,4.122,18.09l1.123-6.545L.489,6.91l6.572-.955L10,0l2.939,5.955,6.572.955-4.755,4.635,1.123,6.545Z"
              transform="translate(2 3)"
              stroke="#ffc800"
              stroke-miterlimit="10"
              stroke-width="1.5"></path>{" "}
          </g>
        </svg>
        <svg
          viewBox="0 0 24 24"
          id="star_filled"
          data-name="star filled"
          xmlns="http://www.w3.org/2000/svg"
          fill="#ffc800">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <rect
              id="Rectangle_4"
              data-name="Rectangle 4"
              width="24"
              height="24"
              fill="none"></rect>{" "}
            <path
              id="Star"
              d="M10,15,4.122,18.09l1.123-6.545L.489,6.91l6.572-.955L10,0l2.939,5.955,6.572.955-4.755,4.635,1.123,6.545Z"
              transform="translate(2 3)"
              stroke="#ffc800"
              stroke-miterlimit="10"
              stroke-width="1.5"></path>{" "}
          </g>
        </svg>
        <svg
          viewBox="0 0 24 24"
          id="star_filled"
          data-name="star filled"
          xmlns="http://www.w3.org/2000/svg"
          fill="#ffc800">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <rect
              id="Rectangle_4"
              data-name="Rectangle 4"
              width="24"
              height="24"
              fill="none"></rect>{" "}
            <path
              id="Star"
              d="M10,15,4.122,18.09l1.123-6.545L.489,6.91l6.572-.955L10,0l2.939,5.955,6.572.955-4.755,4.635,1.123,6.545Z"
              transform="translate(2 3)"
              stroke="#ffc800"
              stroke-miterlimit="10"
              stroke-width="1.5"></path>{" "}
          </g>
        </svg>
        <p > Google</p>
      </div> */}
      <div className="container-scroll">
        <div className="chevron"></div>
        <div className="chevron"></div>
        <div className="chevron"></div>
      </div>
    </div>
  );
};

export default Home;
